import React, {
  Component,
  useState,
  useEffect,
  useContext,
  Button,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import $ from "jquery";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { FaRegFileExcel, FaRegWindowClose } from "react-icons/fa";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Apis } from "api";
import { Text, LanguageContext } from "./../../context/LanguageContext";
import {
  faDownload,
  faUndoAlt,
  faClock,
  faSyncAlt,
  faPlay,
  faStop,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "react-switch";
import Modal from "react-modal";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

const WboothPage = () => {
  const { dictionary } = useContext(LanguageContext);
  const [loader, setLoader] = useState(false);
  const [TimeUpdate, setTimeUpdate] = useState(true);
  const [switchState, setSwitchState] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(null);
  const [dataTest, setDataTest] = useState([]);
  const [datearray, setDatearray] = useState([
    {
      id: 1,
      link: "https://drive.google.com/uc?id=1PEBgfhrPA5ukHwtydpUWiLnrBV8SoZRM&export=download",
      date: "05-05-2023",
    },
    {
      id: 2,
      link: "https://drive.google.com/uc?id=1PEBgfhrPA5ukHwtydpUWiLnrBV8SoZRM&export=download",
      date: "06-05-2023",
    },
    {
      id: 3,
      link: "https://drive.google.com/uc?id=1PEBgfhrPA5ukHwtydpUWiLnrBV8SoZRM&export=download",
      date: "07-05-2023",
    },
    {
      id: 4,
      link: "https://drive.google.com/uc?id=1PEBgfhrPA5ukHwtydpUWiLnrBV8SoZRM&export=download",
      date: "08-05-2023",
    },
    {
      id: 5,
      link: "https://drive.google.com/uc?id=1PEBgfhrPA5ukHwtydpUWiLnrBV8SoZRM&export=download",
      date: "09-05-2023",
    },
    {
      id: 6,
      link: "https://drive.google.com/uc?id=1PEBgfhrPA5ukHwtydpUWiLnrBV8SoZRM&export=download",
      date: "10-05-2023",
    },
    {
      id: 7,
      link: "https://drive.google.com/uc?id=1PEBgfhrPA5ukHwtydpUWiLnrBV8SoZRM&export=download",
      date: "11-05-2023",
    },
  ]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDatefrom, setSelectedDatefrom] = useState(null);
  const [selectedDateto, setSelectedDateto] = useState(null);
  const [selectedMacId, setSelectedMacId] = useState(null);

  const [selectedFromMinimumDate, setselectedFromMinimumDate] = useState(null);
  const [selectedToMinimumDate, setselectedToMinimumDate] = useState(null);
  const [time, setTime] = useState("0");
  const names = [];
  const names1 = [
    {
      UID: "UE97PJL2P27",
      Username: "Avinash",
      Farm_ID: "FC8AC643P37",
      Farm_Name: "farm 01",
      Booths: 1,
      Location: "chennai",
    },
    {
      UID: "U52O1J2030M",
      Username: "parthiban",
      Farm_ID: "FA03IOM00A7",
      Farm_Name: "parthiban",
      Booths: 2,
      Location: "chennai",
    },
    {
      UID: "UI3T9PMAE8E",
      Username: "check",
      Farm_ID: "F55TR313JC8",
      Farm_Name: null,
      Booths: 2,
      Location: "india",
    },
    {
      UID: "UAPEAMH2P4H",
      Username: "admin",
      Farm_ID: "F9C687O631C",
      Farm_Name: "farm123",
      Booths: 2,
      Location: "India",
    },
    {
      UID: "U5AOC1T8T44",
      Username: "viki",
      Farm_ID: "F7MREEATOA2",
      Farm_Name: "aa",
      Booths: 1,
      Location: "21",
    },
    {
      UID: "U659OR6MET6",
      Username: "bin",
      Farm_ID: null,
      Farm_Name: null,
      Booths: null,
      Location: null,
    },
    {
      UID: "UA3AR0928JR",
      Username: "test",
      Farm_ID: null,
      Farm_Name: null,
      Booths: null,
      Location: null,
    },
    {
      UID: "UO7O25ELAT4",
      Username: "hiuy",
      Farm_ID: "FE4LJE9RJ04",
      Farm_Name: "test bootj",
      Booths: 2,
      Location: "India",
    },
    {
      UID: "U5LCO5M8CN5",
      Username: "harikrish",
      Farm_ID: "FHTEA90EACR",
      Farm_Name: null,
      Booths: 2,
      Location: "Chennai ",
    },
    {
      UID: "U1M6E7T1PH9",
      Username: "thamilllll",
      Farm_ID: "F5N84MMA2O3",
      Farm_Name: null,
      Booths: 1,
      Location: "Chennai ",
    },
  ];
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  };

  useEffect(() => {
    $(document).ready(function () {
      $(".pageheads").html(dictionary.Booths);
    });
  });

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#myTable")) {
      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            initComplete: function (settings, json) {
              // alert( 'DataTables has finished its initialisation.' );
              $(".blockdis").show();
              $(".nonedis").hide();
              setLoader(false);
            },
            processing: true,
            stateSave:true,
            bDestroy: true,
            pagingType: "full_numbers",
            autoWidth: false,
            language: {
              loadingRecords: "&nbsp;",
              processing: "Loading...",
              search: "",
              searchPlaceholder: dictionary.search,
              paginate: {
                last: '<img src="images/arrow-right_fast.png">',
                first: '<img src="images/arrow-left_fast.png">',
                previous: '<img src="images/arrow-left.png">',
                next: '<img src="images/arrow-right.png">',
              },
              info:
                dictionary.Showing +
                " " +
                "_START_" +
                " " +
                dictionary.to +
                " " +
                "_END_" +
                " " +
                dictionary.of +
                " " +
                "_TOTAL_" +
                " " +
                dictionary.entries,
              lengthMenu:
                dictionary.show + " " + "_MENU_" + " " + dictionary.entries,
            },
            pageLength: 10,
            processing: true,
            dom: '<"dt-top-container"<"dt-len" l><"dt-center-in-div"f><"buttonsclass" B>r>t<"dt-filter-spacer"><ip>',

            select: {
              style: "single",
            },
            buttons: [
              {
                extend: "excel",
                className: "excelButton",
                text: '<img style="width:32px;height:32px;" src="images/grid_table/excel.png">',
                titleAttr: dictionary.export_excel,
                filename: dictionary.Booths,
              },
            ],
            // buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
            fnRowCallback: function (
              nRow,
              aData,
              iDisplayIndex,
              iDisplayIndexFull
            ) {
              var index = iDisplayIndexFull + 1;
              $("td:first", nRow).html(index);
              return nRow;
            },
            lengthMenu: [
              [10, 20, 50, 100],
              [10, 20, 50, 100],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });
        }, 1000);
      });
    }
  });
  const [Boothlist, setBoothlist] = useState([]);
  useEffect(() => {
    boothList();
  }, []);

  const boothList = () => {
    setLoader(true);
    var data = JSON.parse(localStorage.getItem("Login"));
    var uid = data.data.UID;
    var url = Apis.BoothDetails;

    // var url = "https://tap.isolvedev.in/tap/api/web-user";
    var req = { UID: uid };

    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode == 200) {
          console.log(JSON.stringify(response.data.data));
          if (response.data.data.length == 0) {
            setLoader(false);
          }
          // alert(JSON.stringify(response));
          console.log(JSON.stringify(response.data.data[0]));
          setBoothlist(response.data.data);
          // setBoothlist(response.data.final_data);

          //
        } else {
          // alert("else");
          setLoader(false);
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })

      .catch((error) => {
        setLoader(false);
        // alert(error);
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    defaultDate();
  }, []);

  const defaultDate = () => {
    const priorDate = moment().subtract(7, "days").toDate();
    const today = moment().toDate();
    const apiTo = moment(today).format("YYYY-MM-DD");
    const apiFrom = moment(priorDate).format("YYYY-MM-DD");

    setSelectedDatefrom(apiFrom);
    setSelectedDateto(apiTo);
    setselectedToMinimumDate(apiTo);
  };
  const setBabiesArray = (item) => {
    setDataTest(item);
    handleModalOpen(false, null);
  };

  const handleModalOpen = (status, macid) => {
    setSelectedMacId(macid);
    setIsDownload(status);
    defaultDate();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const datefrom = (newValue) => {
    const end = moment(newValue).add(7, "days");
    const currentDate = moment();
    setSelectedDatefrom(newValue);
    setSelectedDateto(
      end.isAfter(currentDate)
        ? currentDate.format("YYYY-MM-DD")
        : end.format("YYYY-MM-DD")
    );
    setselectedToMinimumDate(
      end.isAfter(currentDate)
        ? currentDate.format("YYYY-MM-DD")
        : end.format("YYYY-MM-DD")
    );
  };

  const dateto = (newValue) => {
    var Date_set = newValue;
    let end = moment(Date_set).format("YYYY-MM-DD");
    setSelectedDateto(end);
  };
  const babyDetailsDownload = () => {
    setLoader(true);
    var data = JSON.parse(localStorage.getItem("Login"));
    var url = Apis.downloadBoothDetails;
    var req = {
      macid: selectedMacId,
      start_date: selectedDatefrom,
      end_date: selectedDateto,
    };
    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode == 200) {
          setLoader(false);

          closeModel(response.data.url);
        } else {
          setLoader(false);
          toast.error(response.data.Message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })

      .catch((error) => {
        setLoader(false);
        // alert(error);
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const closeModel = (url) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "file-to-download.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.addEventListener("click", () => {});
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setIsModalOpen(false);
  };
  const closeModel1 = () => {
    setIsModalOpen(false);
  };

  const showTable = () => {
    try {
      return Boothlist.map((item, index) => {
        return (
          <tr>
            <td style={{ textAlign: "center" }} className="">
              {index + 1}
            </td>

            <td className="">
              <button
                onClick={() => setBabiesArray(item.baby_detail)}
                disabled={item.isActivate && item.isbaby ? false : true}
                style={{
                  textDecoration:
                    item.isActivate && item.isbaby ? "underline" : null,
                  color: item.isActivate && item.isbaby ? "#046664" : null,
                }}
              >
                {item.BID}
              </button>
            </td>

            <td className="">{item.Booth_Name}</td>
            <td className="">{item.Farm_Name}</td>
            <td className="">{item.PigID}</td>
            <td className="">{item.CameraSerial}</td>
            <td className="">
              {" "}
              {moment(item.createdIso).format("MMM Do YYYY, hh:mm a")}
            </td>
            {item.isActivate == true ? (
              <td>
                <div>
                  <div className="act_green"></div>
                  <div className="act_gtext actgreencolor">
                    {dictionary.Active}
                  </div>
                </div>
              </td>
            ) : (
              <td>
                <div>
                  <div className="act_gry"></div>
                  <div className="act_gtext actgreycolor">
                    {dictionary.inactive}
                  </div>
                </div>
              </td>
            )}
            {/* <td>
              <Switch
                onChange={setSwitchState}
                checked={switchState}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#046664"
              />
            </td> */}
            <td className="down_act_green">
              {/* <a
                href="https://drive.google.com/uc?id=1PEBgfhrPA5ukHwtydpUWiLnrBV8SoZRM&export=download"
                download
              >
                <FontAwesomeIcon icon={faDownload} />
              </a> */}

              <button
                onClick={() => handleModalOpen(true, item.CameraSerial)}
                disabled={item.isActivate == true ? false : true}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    color: item.isActivate == true ? "#046664" : "#cccccc",
                  }}
                />
              </button>
            </td>
            <td>
              <div className="inpsumit">
                <input
                  type="text"
                  placeholder="Enter Time"
                  style={{
                    width: 110,
                    background: item.isActivate == true ? "#ffffff" : "#cccccc",
                    color: item.isActivate == true ? "#000000" : "#ffffff",
                  }}
                  value={item.Timing}
                  onChange={(event) => handleChange(event, item.BID)}
                  disabled={item.isTiming && item.isActivate ? false : true}
                />
                {item.isTiming == true ? (
                  <button
                    type="submit"
                    style={{
                      backgroundColor:
                        item.isActivate == true ? "#046664" : "#cccccc",
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: "5px",
                      padding: "10px 20px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      updateTime(item.BID, item.Timing, item.isTiming)
                    }
                    disabled={item.isActivate == true ? false : true}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      style={{ width: 20, height: 20, color: "white" }}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    style={{
                      backgroundColor:
                        item.isActivate == true ? "#ff0000" : "#cccccc",
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: "5px",
                      padding: "10px 20px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    onClick={() => updateTime(item.BID, 0, true)}
                    disabled={item.isActivate == true ? false : true}
                  >
                    <FontAwesomeIcon
                      icon={faStop}
                      style={{ width: 20, height: 20, color: "white" }}
                    />
                  </button>
                )}
              </div>
            </td>
          </tr>
        );
      });
    } catch (e) {
      // alert(e.message);
    }
  };
  const handleChange = (event, BID) => {
    let updatedBoothlist = Boothlist.map((booth) => {
      if (booth.BID === BID) {
        return { ...booth, Timing: event.target.value };
      }
      return booth;
    });
    setBoothlist(updatedBoothlist);
  };

  const updateTime = (BID, TIME, TIMESTATUS) => {
    setLoader(true);
    var url = Apis.BoothUpdateTimeDetails;
    var req = {
      bid: BID,
      isTiming: TIME == "" || 0 ? true : false,
      Timing: TIME == "" ? 0 : TIME,
    };

    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode == 200) {
          setLoader(false);
          boothList();
        }
      })

      .catch((error) => {
        setLoader(false);
        // alert(error);
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div className="pagetp">
      <div className="row">
        <div className="col-12">
          {loader ? (
            <div className="overlay">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spinner">
                    <img
                      src={"/images/loader1.gif"}
                      alt=""
                      className="loadersiz"
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            padding:10,
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button onClick={() => boothList()}>
            <FontAwesomeIcon
              icon={faRefresh}
              style={{
                color: "#046664",
              }}
            />
          </button>
        </div>
      </div>
      {Boothlist.length != 0 ? (
        <div>
          {/* <div class="nonedis Tableprocessing">{dictionary.loading}</div> */}
          <div
            style={{ display: "none" }}
            class="blockdis table-responsive p-0 pb-2"
          >
            <table id="table" className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style={{ width: 40 }} className="">
                    {dictionary.sno}
                  </th>
                  <th style={{ width: 120 }} className="">
                    {dictionary.boothid}
                  </th>
                  <th style={{ width: 150 }} className="">
                    {dictionary.boothname}
                  </th>
                  <th style={{ width: 150 }} className="">
                    {dictionary.farmname}
                  </th>
                  <th style={{ Width: 150 }} className="">
                    {dictionary.pigid}
                  </th>
                  <th style={{ minWidth: 150 }} className="">
                    {dictionary.macaddress}
                  </th>
                  <th style={{ minWidth: 90 }} className="">
                    {dictionary.createddate}
                  </th>
                  <th style={{ width: 90 }} className="">
                    {dictionary.status}
                  </th>
                  {/* <th style={{ width: 110 }} className="">
                    active status
                  </th> */}
                  <th style={{ width: 10 }} className="">
                    {dictionary.download}
                  </th>
                  <th style={{ width: 50 }} className="">
                    {dictionary.timemin}
                  </th>
                </tr>
              </thead>

              <tbody>{showTable()}</tbody>
            </table>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={handleModalClose}
              style={{
                content: { width: "500px", height: "300px", margin: "auto" },
              }}
              style={customStyles}
              ariaHideApp={false}
            >
              {isDownload ? (
                <div style={{ padding: "20px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div> </div>
                    <div>
                      <h2
                        style={{
                          color: "#046664",
                          fontWeight: "bold",
                          borderBottomColor: "#046664",
                          borderBottomWidth: "2px",
                        }}
                      >
                        {dictionary.Reports}
                      </h2>
                    </div>
                    <div>
                      <div className="closemod">
                        <div className="closecross">
                          <button onClick={() => closeModel1()}>
                            <FaRegWindowClose />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",

                      marginTop: "20px",
                    }}
                  >
                    <div className="row">
                      <div className="datepicinput1">
                        <div className="datepicc">
                          <label>{dictionary.fromdate}</label>
                          <br />
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              placeholder="From date"
                              value={selectedDatefrom}
                              maxDate={selectedDateto}
                              variant="standard"
                              InputProps={{ disableUnderline: true }}
                              onChange={datefrom}
                              format="dd-MM-yyyy"
                              disableFuture
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>

                        <div className="datepicc">
                          <label>{dictionary.todate}</label> <br />
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              placeholder="To date"
                              value={selectedDateto}
                              minDate={selectedDatefrom}
                              maxDate={selectedToMinimumDate}
                              variant="standard"
                              InputProps={{ disableUnderline: true }}
                              onChange={dateto}
                              format="dd-MM-yyyy"
                              disableFuture
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <h2
                      style={{
                        color: "#046664",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textAlign: "center",
                        paddingTop: 15,
                      }}
                    >
                      {dictionary.downloadlinks}
                    </h2>
                  </div> */}
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    {datearray.map((row, index) => (
                      <button
                        onClick={() => closeModel(row.link)}
                        style={{
                          textDecoration: "underline",
                          paddingTop: 15,
                          color: "#046664",
                        }}
                      >
                        {row.date}
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ color: "white" }}
                        />
                      </button>
                    ))}
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      type="submit"
                      style={{
                        backgroundColor: "#046664",
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "5px",
                        padding: "10px 20px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                      onClick={() => babyDetailsDownload()}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        style={{ color: "white" }}
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    padding: "20px",
                    maxHeight: "700px",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div> </div>
                    <div>
                      <h2
                        style={{
                          color: "#046664",
                          fontWeight: "bold",
                          borderBottomColor: "#046664",
                          borderBottomWidth: "2px",
                        }}
                      >
                        {dictionary.pigdetails}
                      </h2>
                    </div>
                    <div>
                      <div className="closemod">
                        <div className="closecross">
                          <button onClick={() => closeModel1()}>
                            <FaRegWindowClose />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{dictionary.sno}</TableCell>
                        <TableCell>{dictionary.name}</TableCell>
                        <TableCell>{dictionary.date}</TableCell>
                        <TableCell>{dictionary.time}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataTest.map((row, index) => (
                        <TableRow key={index + 1}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.Brith_date}</TableCell>
                          <TableCell>{row.Brith_time}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </Modal>
          </div>
          <ToastContainer
            // className="toast-position"
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      ) : null}
    </div>
  );
};

export default WboothPage;
