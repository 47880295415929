import React, { Component, useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import $ from "jquery";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { FaRegFileExcel } from "react-icons/fa";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { mockDataContacts } from "../../data/mockData";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Topheader from "components/Topheader/Topheader";
import { Audio } from "react-loader-spinner";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/Loading";
import { Apis } from "api";
import { Text, LanguageContext } from "./../../context/LanguageContext";

const WusersPage = () => {
  const { dictionary } = useContext(LanguageContext);
  const names = [
    {
      username: "Chistine",
      farmid: "F0001",
      farmname: "Happy farm",
      booths: "10",
      location: "Moss, Norway",
    },
    {
      username: "Surya",
      farmid: "F0001",
      farmname: "Happy farm",
      booths: "15",
      location: "Moss, Norway",
    },
    {
      username: "Oslo, Norway",
      farmid: "F0003",
      farmname: "Green Valey farm",
      booths: "10",
      location: "Moss, Norway",
    },
    {
      username: "Norway",
      farmid: "F0013",
      farmname: "Valey farm",
      booths: "15",
      location: "Moss, Norway",
    },
  ];
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "registrarId", headerName: "Registrar ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      flex: 1,
    },
  ];

  const names1 = [
    {
      UID: "UE97PJL2P27",
      Username: "Avinash",
      Farm_ID: "FC8AC643P37",
      Farm_Name: "farm 01",
      Booths: 1,
      Location: "chennai",
    },
    {
      UID: "U52O1J2030M",
      Username: "parthiban",
      Farm_ID: "FA03IOM00A7",
      Farm_Name: "parthiban",
      Booths: 2,
      Location: "chennai",
    },
    {
      UID: "UI3T9PMAE8E",
      Username: "check",
      Farm_ID: "F55TR313JC8",
      Farm_Name: null,
      Booths: 2,
      Location: "india",
    },
    {
      UID: "UAPEAMH2P4H",
      Username: "admin",
      Farm_ID: "F9C687O631C",
      Farm_Name: "farm123",
      Booths: 2,
      Location: "India",
    },
    {
      UID: "U5AOC1T8T44",
      Username: "viki",
      Farm_ID: "F7MREEATOA2",
      Farm_Name: "aa",
      Booths: 1,
      Location: "21",
    },
    {
      UID: "U659OR6MET6",
      Username: "bin",
      Farm_ID: null,
      Farm_Name: null,
      Booths: null,
      Location: null,
    },
    {
      UID: "UA3AR0928JR",
      Username: "test",
      Farm_ID: null,
      Farm_Name: null,
      Booths: null,
      Location: null,
    },
    {
      UID: "UO7O25ELAT4",
      Username: "hiuy",
      Farm_ID: "FE4LJE9RJ04",
      Farm_Name: "test bootj",
      Booths: 2,
      Location: "India",
    },
    {
      UID: "U5LCO5M8CN5",
      Username: "harikrish",
      Farm_ID: "FHTEA90EACR",
      Farm_Name: null,
      Booths: 2,
      Location: "Chennai ",
    },
    {
      UID: "U1M6E7T1PH9",
      Username: "thamilllll",
      Farm_ID: "F5N84MMA2O3",
      Farm_Name: null,
      Booths: 1,
      Location: "Chennai ",
    },
  ];
  const [Userlist, setUserlist] = useState([]);
  const [Userloading, setUserloading] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    $(document).ready(function () {
      $(".pageheads").html(dictionary.Users);
    });
  }, []);

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#myTable")) {
      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            initComplete: function (settings, json) {
              // alert( 'DataTables has finished its initialisation.' );
              $(".blockdis").show();
              $(".nonedis").hide();
              setLoader(false);
            },
            processing: true,
            bDestroy: true,
            pagingType: "full_numbers",
            autoWidth: false,
            language: {
              loadingRecords: "&nbsp;",
              processing: "Loading...",
              search: "",
              searchPlaceholder: dictionary.search,
              paginate: {
                last: '<img src="images/arrow-right_fast.png">',
                first: '<img src="images/arrow-left_fast.png">',
                previous: '<img src="images/arrow-left.png">',
                next: '<img src="images/arrow-right.png">',
              },
              info:
                dictionary.Showing +
                " " +
                "_START_" +
                " " +
                dictionary.to +
                " " +
                "_END_" +
                " " +
                dictionary.of +
                " " +
                "_TOTAL_" +
                " " +
                dictionary.entries,
              lengthMenu:
                dictionary.show + " " + "_MENU_" + " " + dictionary.entries,
            },
            pageLength: 10,
            processing: true,
            dom: '<"dt-top-container"<"dt-len" l><"dt-center-in-div"f><"buttonsclass" B>r>t<"dt-filter-spacer"><ip>',

            select: {
              style: "single",
            },
            buttons: [
              {
                extend: "excel",
                className: "excelButton",
                text: '<img style="width:32px;height:32px;" src="images/grid_table/excel.png">',
                titleAttr: dictionary.export_excel,
                filename: dictionary.Users,
              },
            ],
            // buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
            fnRowCallback: function (
              nRow,
              aData,
              iDisplayIndex,
              iDisplayIndexFull
            ) {
              var index = iDisplayIndexFull + 1;
              $("td:first", nRow).html(index);
              return nRow;
            },
            lengthMenu: [
              [10, 20, 50, 100],
              [10, 20, 50, 100],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });
        }, 1000);
      });
    }
  });

  useEffect(() => {
    setLoader(true);
    var data = JSON.parse(localStorage.getItem("Login"));
    var uid = data.data.UID;
    var url = Apis.UserDetails;
    var req = { UID: uid };
    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          if (response.data.data.length == 0) {
            setLoader(false);
          }

          var data = response.data.data;
          setUserlist(data);

          // showTable();
        } else {
          setLoader(false);
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoader(false);

        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, []);

  const showTable = () => {
    return Userlist.map((item, index) => {
      return (
        <tr>
          <td style={{ textAlign: "center" }} className="">
            {index + 1}
          </td>
          <td className="">{item.Username}</td>
          <td className="">{item.Farm_Name}</td>
          <td className="">{item.Farm_ID}</td>

          <td className="">{item.Booths}</td>
          <td className="">{item.Location}</td>
        </tr>
      );
    });
  };

  return (
    <div className="pagetp">
      <div className="row">
        <div className="col-12">
          {loader ? (
            <div className="overlay">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spinner">
                    <img
                      src={"/images/loader1.gif"}
                      alt=""
                      className="loadersiz"
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {Userlist.length != 0 ? (
        <div>
          {/* <div class="nonedis Tableprocessing">{dictionary.loading}</div> */}
          <div
            style={{ display: "none" }}
            class="blockdis table-responsive p-0 pb-2"
          >
            <table id="table" className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style={{ width: 40 }} className="">
                    {dictionary.sno}
                  </th>
                  <th style={{ minWidth: 200 }} className="">
                    {dictionary.username}
                  </th>
                  <th style={{ width: 200 }} className="">
                    {dictionary.farmname}
                  </th>
                  <th style={{ width: 100 }} className="">
                    {dictionary.farmid}
                  </th>

                  <th style={{ width: 90 }} className="">
                    {dictionary.booths}
                  </th>
                  <th style={{ minWidth: 200 }} className="">
                    {dictionary.location}
                  </th>
                </tr>
              </thead>
              <tbody>{showTable()}</tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
    // <div>
    //   <Box m="20px">
    //     <Box
    //       m="40px 0 0 0"
    //       height="75vh"
    //       sx={{
    //         "& .MuiDataGrid-root": {
    //           border: "none",
    //         },
    //         "& .MuiDataGrid-cell": {
    //           borderBottom: "none",
    //         },
    //         "& .name-column--cell": {
    //           color: colors.greenAccent[300],
    //         },
    //         "& .MuiDataGrid-columnHeaders": {
    //           backgroundColor: colors.blueAccent[700],
    //           borderBottom: "none",
    //         },
    //         "& .MuiDataGrid-virtualScroller": {
    //           backgroundColor: colors.primary[400],
    //         },
    //         "& .MuiDataGrid-footerContainer": {
    //           borderTop: "none",
    //           backgroundColor: colors.blueAccent[700],
    //         },
    //         "& .MuiCheckbox-root": {
    //           color: `${colors.greenAccent[200]} !important`,
    //         },
    //         "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
    //           color: `${colors.grey[100]} !important`,
    //         },
    //       }}
    //     >
    //       <DataGrid
    //         rows={mockDataContacts}
    //         columns={columns}
    //         components={{ Toolbar: GridToolbar }}
    //       />
    //     </Box>
    //   </Box>
    // </div>
  );
};

export default WusersPage;
