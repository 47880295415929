import React, { useState, useRef } from "react";

function CameraComponent({ onImageCapture }) {
  const inputRef = useRef(null);

  const handleCameraClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        onImageCapture(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <button onClick={handleCameraClick}>Open Camera</button>
      <input
        type="file"
        accept="image/*"
        capture="environment"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
    </div>
  );
}

function ContactForm() {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [imageData, setImageData] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);

  const handleSubmit = async () => {
    try {
      console.log("Name:", name);
      console.log("Phone Number:", phoneNumber);
      console.log("ImageData:", imageData);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phoneNumber", phoneNumber);
      formData.append("imageData", imageData);
      console.log(formData);
      const response = await fetch("YOUR_API_ENDPOINT", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setApiResponse(data);
      } else {
        // Handle error cases
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <label>
        Name:
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <br />
      <label>
        Phone Number:
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </label>
      <br />
      {imageData && <img src={imageData} alt="Captured" width="150" />}
      <br />
      <CameraComponent onImageCapture={setImageData} />
      <br />
      <button onClick={handleSubmit}>Submit</button>

      {apiResponse && (
        <div>
          <p>API Response:</p>
          <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default ContactForm;
