const BASE = "https://dev.vjuutech.no";

export const Apis = {
  Login: BASE + "/tap/api/admin-login",
  GetNotes: BASE + "/tap/api/view-user",
  DashBoardDetails: BASE + "/tap/api/web-dashboard",
  FarmDetails: BASE + "/tap/api/web-farm",
  LineChart: BASE + "/tap/api/web-linechart",
  UpdateNotes: BASE + "/tap/api/web-update",
  BoothDetails: BASE + "/tap/api/web-booth",
  downloadBoothDetails: BASE + "/tap/api/download-file",
  BoothUpdateTimeDetails: BASE + "/tap/api/web-booth-update",
  UserDetails: BASE + "/tap/api/web-user",
  AlertDetails: BASE + "/tap/api/web-notification",
  WebBoothDetails: BASE + "/tap/api/web-booth-data",
  WebfarmDetails: BASE + "/tap/api/web-farm-data",
  analitices: BASE + "/tap/api/web-temperature",
};
