import React from "react";
import WAlerts from "pages/WAlerts";
import WAnalitices from "pages/WAnalitices";
import Wusers from "pages/Wusers";
import Wbooth from "pages/Wbooth";
import WFarms from "pages/WFarms";
import WDashboard from "pages/WDashboard";
import WSplashscreen from "pages/WSplashscreen";
import ContactForm from "pages/ContactForm";
import Login from "pages/login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import PrivateRoute from "./PrivateRoute";
import Sidebar from "shared/navbar/Sidebar";
const ProjectRoutes = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/wsplashscreen" component={WSplashscreen} />
        <PrivateRoute path="/wdashboard" component={WDashboard} />
        <PrivateRoute path="/wfarms" component={WFarms} />
        <PrivateRoute path="/wbooth" component={Wbooth} />
        <PrivateRoute path="/wusers" component={Wusers} />
        <PrivateRoute path="/walerts" component={WAlerts} />
        <PrivateRoute path="/wanalitices" component={WAnalitices} />
        <PrivateRoute path="/contactForm" component={ContactForm} />
        <Route path="/" component={Login} />
        <Route path="/login" component={Login} />
      </Switch>
    </Router>
  );
};
export default ProjectRoutes;
