import en from "./en.json";
import tn from "./tn.json";
import no from "./no.json";

export const dictionaryList = { en, no };

export const languageOptions = {
  no: "NO",
  en: "EN",

  // tn: "Tamil",
};
