import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import "shared/navbar/navbar.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Icon from "../../assets/images/Vlogo.png";
import { Text, LanguageContext } from "./../../context/LanguageContext";

const Sidebar = () => {
  const [Dashboard, setDashboard] = useState(true);
  const [Farms, setFarms] = useState(true);
  const [Booths, setBooths] = useState(true);
  const [Users, setUsers] = useState(true);
  const [Alerts, setAlerts] = useState(true);
  const [Analytics, setAnalytics] = useState(true);
  // const history = useHistory();

  const logoutHandler = () => {
    localStorage.clear();
  };

  // const logoutHandler = () => {
  //   var data = JSON.parse(localStorage.getItem("Login"));
  //   var uid = data.data.UID;
  //   var url = "https://dev.vjuutech.no/tap/api/logout-user";
  //   var req = {
  //     uid: uid,
  //   };

  //   const requestHeader = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   axios
  //     .post(url, req, requestHeader)
  //     .then(async (response) => {
  //       if (response.data.statuscode == 200) {
  //         history.push("/login");
  //       } else {
  //         history.push("/login");
  //       }
  //     })

  //     .catch((error) => {
  //       toast.error(error, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     });
  // };
  return (
    <div className="">
      <aside className="sidebar-sec">
        {/* <div className="brandname_logo">Vjupig</div> */}
        <div className="brandname_logo">
          <img className="brand_img_logo" src={Icon} />
        </div>
        {/* <div className="searchmenu">
      <input
        className="form-control menuser"
        type="text"
        placeholder="Search"
      />
      <span className="menu_searchicon"
        ><i className="fa fa-search"></i></span>
  </div> */}

        <div className="sidebar-scroll">
          <ul className="sidebar-menu">
            {/* <li class="">
        <Link to="/wdashboard">WDashboard</Link>
       </li>
       <li class="">
       <Link to="/wfarms">WFarms</Link>
       </li>
       <li class="">
       <Link to="/wbooth">Wbooth</Link>
       </li>
       <li class="">
       <Link to="/wusers">Wusers</Link>
       </li>
       <li class="">
       <Link to="/walerts">WAlerts</Link>
       </li>

       <li class="main-menu has-collapsible">
       <Link to="/wdashboard"><span><img src={'/images/sidenav/dashboard.png'} alt="" class="menu-img" /></span><span>Dashboard</span></Link>
        </li> */}
            {/* {Dashboard ? (
              <li class="main-menu has-collapsible">
                <NavLink exact activeClassName="active" to="/wdashboard">
                  <span>
                    <img
                      src={"images/sidenav/dashboard.png"}
                      alt=""
                      class="menu-img"
                    />
                  </span>
                  <span>
                    <Text tid="Dashboard" />
                  </span>
                </NavLink>
              </li>
            ) : null} */}
            {Farms ? (
              <li class="main-menu has-collapsible">
                <NavLink activeClassName="active" to="/wfarms">
                  <span>
                    <img
                      src={"images/sidenav/ai.png"}
                      alt=""
                      class="menu-img"
                    />
                  </span>
                  <span>
                    <Text tid="Farms" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {Booths ? (
              <li class="main-menu has-collapsible">
                <NavLink activeClassName="active" to="/wbooth">
                  <span>
                    <img
                      src={"images/sidenav/booth.png"}
                      alt=""
                      class="menu-img"
                    />
                  </span>
                  <span>
                    <Text tid="Booths" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {Users ? (
              <li class="main-menu has-collapsible">
                <NavLink activeClassName="active" to="/wusers">
                  <span>
                    <img
                      src={"images/sidenav/users.png"}
                      alt=""
                      class="menu-img"
                    />
                  </span>
                  <span>
                    <Text tid="Users" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {Alerts ? (
              <li class="main-menu has-collapsible">
                <NavLink activeClassName="active" to="/walerts">
                  <span>
                    <img
                      src={"images/sidenav/caution.png"}
                      alt=""
                      class="menu-img"
                    />
                  </span>
                  <span>
                    <Text tid="Alerts" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {/* {Analytics ? (
              <li class="main-menu has-collapsible">
                <NavLink activeClassName="active" to="/wanalitices">
                  <span>
                    <img
                      src={"images/sidenav/stat.png"}
                      alt=""
                      class="menu-img"
                    />
                  </span>
                  <span>
                    <Text tid="Analytics" />
                  </span>
                </NavLink>
              </li>
            ) : null} */}

            {/* <li class="main-menu has-collapsible">
              <Link to="">
                <span>
                  <img
                    src={"images/sidenav/stat.png"}
                    alt=""
                    class="menu-img"
                  />
                </span>
                <span>Reports</span>
              </Link>
            </li> */}

            {/* <li class="main-menu has-collapsible">
              <Link to="">
                <span>
                  <img
                    src={"images/sidenav/setting.png"}
                    alt=""
                    class="menu-img"
                  />
                </span>
                <span>Settings</span>
              </Link>
            </li> */}

            {/* <li className="main-menu has-collapsible">
          <a><span></span><span class="arrowupdown">CRM</span></a>
          <ul className="sub-menu menu-child">
            <li className="main-menu">
              <a routerLink="/ipick/Tele-calling" routerLinkActive="router-link-active"  routerLinkActive="active"><span>Registration</span></a>
            </li>
            <li className="main-menu">
              <a routerLink="/ipick/assignParamedicDiagnostics" routerLinkActive="active"><span>Assign</span></a>
            </li> 
        </ul>
        </li> */}
          </ul>
        </div>
        <div className="foot-text">
          <div className="logout_side" onClick={logoutHandler}>
            <li>
              <NavLink to="">
                <span>
                  <img
                    src={"images/sidenav/setting.png"}
                    alt=""
                    class="menu-img"
                  />
                </span>
                <span className="logouml">
                  <Text tid="Logout" />
                </span>
              </NavLink>
            </li>
          </div>
          <div className="log_power_m">
            <Text tid="poweredby" />
          </div>
          <div className="logbut_log_m">
            <div className="log_com_logo_m">
              <img className="log_com_m" src="images/isolve_logo.png" />
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};
export default Sidebar;
