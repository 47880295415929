import React, { useState, useContext } from "react";
import "./login.css";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Apis } from "../../api/index";
import Icon from "../../assets/images/Vlogo.png";
import { Text, LanguageContext } from "./../../context/LanguageContext";
import { languageOptions } from "./../../language";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const handleLanguageChange = (e) => userLanguageChange(e.target.value);
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();
  const loginHandler = () => {
    var url = Apis.Login;

    var req = {
      name: username,

      pwd: password,
    };

    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode == 200) {
          localStorage.setItem("getToken", 1);
          localStorage.setItem("Login", JSON.stringify(response.data));
          localStorage.setItem(
            "LoginName",
            JSON.stringify(response.data.data.Name)
          );
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            // history.push("/wdashboard");
            history.push("/WFarms");
          }, 3000);
          //
        } else {
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setUserName("");
          setPassword("");
        }
      })

      .catch((error) => {
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  // var url = "http://tap.isolvedev.in/tap/api/admin-login";

  return (
    <div className="">
      <div
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('images/img_image3.png')`,
        }}
        class="log_content"
      >
        <div className="loginbox">
          <div className="brandname">
            <img className="brand_img_log" src={Icon} />
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Text className="b_log_text" tid="login" />
            <div className="b_log_text">
              <select onChange={handleLanguageChange} value={userLanguage}>
                {Object.entries(languageOptions).map(([id, name]) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <form>
            <div className="loginput">
              <input
                type="text"
                name="name"
                placeholder={dictionary.username}
                value={username}
                onChange={(e) => setUserName(e.target.value)}
              />
              <div class="log_inputs_ic">
                <img className="log_ic" src={"images/icon_user.png"} />
              </div>
            </div>

            <div className="loginput">
              <input
                type="password"
                name="name"
                placeholder={dictionary.password}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setPassword(e.target.value);
                    loginHandler();
                  }
                }}
              />
              <div class="log_inputs_ic">
                <img className="log_ic" src={"images/icon_password.png"} />
              </div>
            </div>
            {username != "" && password != "" ? (
              <div className="logbut">
                <Button
                  className="btn btn_log"
                  variant="contained"
                  onClick={loginHandler}
                >
                  <Text tid="login" />
                </Button>
              </div>
            ) : (
              <div className="logbut">
                <Button
                  className="btn btn_log1"
                  variant="contained"
                  disabled={true}
                >
                  <Text tid="login" />
                </Button>
              </div>
            )}

            <div className="log_power">
              <Text tid="poweredby" />
            </div>

            <div className="logbut_log">
              <div className="log_com_logo">
                <img className="log_com" src={"images/isolve_logo.png"} />
              </div>
            </div>
          </form>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  );
};
export default Login;
