import React from "react";

import { Stack, Img, Column, Text, Row, Button } from "components";
// import { useNavigate } from "react-router-dom";

const WSplashscreenPage = () => {
  // const navigate = useNavigate();

  // function handleNavigate19() {
  //   navigate("/wdashboard");
  // }

  return (
    <>
      <Stack className="bg-white_A700 font-solway h-[1080px] mx-[auto] relative w-[100%]">
        <Img
          src="images/img_image3.png"
          className="absolute h-[1078px] max-w-[100%] top-[0] w-[100%]"
          alt="imageThree"
        />
        <Column className="absolute bg-black_900_bf flex flex-col items-center justify-start md:p-[102px] sm:p-[15px] p-[199px] w-[100%]">
          <Column className="bg-white_A700_e5 flex flex-col items-center justify-end max-w-[562px] md:mb-[10px] mb-[21px] sm:mb-[8px] sm:mx-[0] md:p-[14px] sm:p-[15px] p-[28px] sm:pl-[15px] sm:pr-[15px] rounded-radius15 shadow-bs1 w-[100%]">
            <Text
              className="md:mt-[12px] mt-[24px] sm:mt-[9px] not-italic text-black_900 w-[auto]"
              as="h3"
              variant="h3"
            >
              Vjupig
            </Text>
            <Column className="flex flex-col font-poppins justify-start sm:mt-[23px] md:mt-[30px] mt-[59px] sm:mx-[0] sm:px-[0] sm:w-[100%] w-[93%]">
              <Text
                className="font-normal not-italic text-black_900 w-[auto]"
                as="h2"
                variant="h2"
              >
                Login
              </Text>
              <Row className="bg-gray_100 flex flex-row md:flex-wrap sm:flex-wrap items-center sm:mt-[15px] md:mt-[20px] mt-[40px] md:p-[12px] sm:p-[15px] p-[25px] rounded-radius10 w-[100%]">
                <Img
                  src="images/img_user.svg"
                  className="flex-shrink-0 sm:h-[10px] md:h-[12px] h-[23px] max-w-[100%] sm:ml-[2px] md:ml-[3px] ml-[7px] md:w-[11px] w-[23px] sm:w-[9px]"
                  alt="user"
                />
                <Text
                  className="flex-grow md:ml-[12px] ml-[24px] sm:ml-[9px] mt-[1px] not-italic text-black_900"
                  as="h4"
                  variant="h4"
                >
                  Username
                </Text>
              </Row>
              <Row className="bg-gray_100 flex flex-row md:flex-wrap sm:flex-wrap items-center sm:mt-[13px] md:mt-[18px] mt-[35px] md:p-[13px] sm:p-[15px] p-[27px] rounded-radius10 w-[100%]">
                <Img
                  src="images/img_lock.svg"
                  className="flex-shrink-0 sm:h-[10px] md:h-[12px] h-[23px] max-w-[100%] ml-[4px] md:w-[11px] w-[23px] sm:w-[9px]"
                  alt="lock"
                />
                <Text
                  className="flex-grow mb-[1px] md:ml-[12px] ml-[24px] sm:ml-[9px] not-italic text-black_900"
                  as="h4"
                  variant="h4"
                >
                  Password
                </Text>
              </Row>
              <Button
                className="common-pointer cursor-pointer font-normal min-w-[64%] sm:ml-[33px] md:ml-[43px] ml-[84px] sm:mt-[24px] md:mt-[31px] mt-[61px] not-italic text-[18px] text-center text-white_A700 w-[max-content]"
                // onClick={handleNavigate19}
                shape="RoundedBorder32"
                size="sm"
                variant="FillOrange400"
              >
                Login
              </Button>
              <Text
                className="md:ml-[101px] ml-[197px] sm:ml-[78px] sm:mt-[13px] md:mt-[17px] mt-[34px] text-bluegray_900 w-[auto]"
                variant="body4"
              >
                Powered by
              </Text>
              <Img
                src="images/img_isolve1.png"
                className="max-w-[100%] ml-[182px] sm:ml-[72px] md:ml-[93px] mt-[4px] w-[25%]"
                alt="iSolveOne"
              />
            </Column>
          </Column>
        </Column>
      </Stack>
    </>
  );
};

export default WSplashscreenPage;
