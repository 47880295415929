import React, { Component, useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Button from "@mui/material/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import $ from "jquery";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { FaRegFileExcel } from "react-icons/fa";
import Topheader from "components/Topheader/Topheader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import { Apis } from "api";
import { Text, LanguageContext } from "./../../context/LanguageContext";

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  // lineTension: 5,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

export const datass = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
  },
  labels: ["Normal Temperature", "High Temperature"],
  datasets: [
    {
      label: "Temperature in %",
      data: [90, 10],
      backgroundColor: ["rgba(184, 224, 92, 1)", "rgba(231, 177, 70, 1)"],
      borderColor: ["rgba(184, 224, 92, 1)", "rgba(231, 177, 70, 1)"],
      borderWidth: 1,
    },
  ],
};

const options_donut = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    options: {
      // This chart will not respond to mousemove, etc
      events: ["click"],
    },
  },
};

const WDashboardPage = () => {
  const { dictionary } = useContext(LanguageContext);
  const [loader, setLoader] = useState(false);

  const [selectedDatefrom, SelectedDatefrom] = useState(null);
  const [selectedDateto, SelectedDateto] = useState(null);
  const [UserDashbord, setUserDashbord] = useState([]);
  const [weeklist, setWeeklist] = useState([]);
  const [Farmlist, setFarmlist] = useState([]);
  const [Boothlist, setBoothlist] = useState([]);
  const [UserDailyDetails, setUserDailyDetails] = useState([]);
  const [FormDailyDetails, setFormDailyDetails] = useState([]);
  const [BoothDailyDetails, setBoothDailyDetails] = useState([]);
  const [LineChartXDetails, setLineChartXDetails] = useState({});
  const [LineChartYDetails, setLineChartYDetails] = useState([]);
  const [PieChartDetails, setPieChartDetails] = useState([100, 0]);
  const [UserNotes, setUserNotes] = useState("");
  const [UserId, setUserId] = useState("");
  const [Today, setToday] = useState(0);
  const [Week, setWeek] = useState(0);
  const [Month, setMonth] = useState(0);
  const [Pmonth, setPmonth] = useState(0);
  const [PWeekId, setPWeekId] = useState(1);
  const [PFarmId, setPFarmId] = useState("");
  const [PBoothId, setPBoothId] = useState(null);
  const [chartData, setChartData] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    labels: [dictionary.normaltemperture, dictionary.hightemperture],
    datasets: [
      {
        label: "Temperature in %",
        data: [100, 0],
        backgroundColor: ["rgba(184, 224, 92, 1)", "rgba(231, 177, 70, 1)"],
        borderColor: ["rgba(184, 224, 92, 1)", "rgba(231, 177, 70, 1)"],
        borderWidth: 1,
      },
    ],
  });

  const [linechartData, setlinechartData] = useState({
    colors: ["rgba(231, 177, 70, 1)"],
    markers: {
      size: [4, 7],
    },
    tooltip: {
      shared: false,
      intersect: true,
    },
    chart: {
      height: "400px",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
    },
  });

  const [linechartYData, setlinechartYData] = useState([
    {
      name: "Alerts",
      data: [],
    },
  ]);

  const datefrom = (newValue) => {
    // alert(newValue);
    SelectedDatefrom(newValue);
    var Date_set = newValue;
    var local = moment.utc(Date_set).local().format("DD/MM/YYYY");
    // console.log(local);
  };

  const dateto = (newValue) => {
    // alert(newValue);
    SelectedDateto(newValue);
    var Date_set = newValue;
    var local = moment.utc(Date_set).local().format("DD/MM/YYYY");
    // console.log(local);
  };

  const options_apex = {
    labels: [dictionary.normaltemperture, dictionary.hightemperture],
    colors: ["rgba(184, 224, 92, 1)", "rgba(231, 177, 70, 1)"],
    legend: {
      show: true,
      position: "bottom",
    },
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        position: "right",
        offsetY: 0,
        height: 230,
      },
    },
  };

  const series_apex = [44, 55];

  // const series_line = [
  //   {
  //     name: "Guests",
  //     data: [19, 22, 20, 26]
  //   }
  // ];
  // const options_line = {
  //   xaxis: {
  //     categories: ["2019-05-01", "2019-05-02", "2019-05-03", "2019-05-04"]
  //   }
  // };

  const options_line = {
    markers: {
      size: [4, 7],
    },
    tooltip: {
      shared: false,
      intersect: true,
    },
    chart: {
      height: "400px",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
    },
  };
  const series_line = [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ];

  useEffect(() => {
    $(document).ready(function () {
      $(".pageheads").html(dictionary.Dashboard);
    });
  }, []);
  const getNotes = () => {
    var data = JSON.parse(localStorage.getItem("Login"));
    var uid = data.data.UID;
    setUserId(uid);
    var url = Apis.GetNotes;
    var req = { uid: uid };
    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          var data = response.data.data;
          setUserNotes(data.notes);
        } else {
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const dataBinding = (data) => {
    setToday(data.alaram[0].today_alert);
    setWeek(data.alaram[0].week_alert);
    setMonth(data.alaram[0].month_alert);
    setPmonth(data.alaram[0].previous_alert);
    var tem = data.temperature;
    var temperature = [];
    temperature.push(tem[0].low_tem, tem[0].high_temp);
    setPieChartDetails(temperature);
    setChartData({
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
        },
      },
      labels: [dictionary.normaltemperture, dictionary.hightemperture],
      datasets: [
        {
          label: "Temperature in %",
          data: temperature,
          backgroundColor: ["rgba(184, 224, 92, 1)", "rgba(231, 177, 70, 1)"],
          borderColor: ["rgba(184, 224, 92, 1)", "rgba(231, 177, 70, 1)"],
          borderWidth: 1,
        },
      ],
    });
    // var line = data.Linearray;
    // var linedata = [];
    // var linedata1 = [];

    // for (var i = 0; i < line.length; i++) {
    //   linedata.push(line[i].alert);
    //   linedata1.push(line[i].Date)
    // }
    // setlinechartData({
    //   labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
    //   datasets: [
    //     {
    //       label: "Dataset 1",
    //       data: linedata,
    //       borderColor: "rgba(253, 145, 54, 1)",
    //       backgroundColor: "rgba(253, 145, 54, 1)",
    //       lineTension: 0.5,
    //     },
    //   ],
    // });
    setFormDailyDetails(data.form_data);
    setBoothDailyDetails(data.both_data);
    // setLineChartDetails(data.line_data);
  };
  const DashboardDetails = () => {
    setLoader(true);
    var data = JSON.parse(localStorage.getItem("Login"));
    var uid = data.data.UID;
    setUserId(uid);
    var url = Apis.DashBoardDetails;
    var req = { uid: uid };
    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        setLoader(false);
        if (response.data.statuscode === 200) {
          var data = response.data;
          dataBinding(data);
        } else {
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const linedataBinding = (data) => {
    var line = data.Linearray;
    var linedata = [];
    var linedata1 = [];

    for (var i = 0; i < line.length; i++) {
      linedata.push(line[i].alert);
      linedata1.push(line[i].Date);
    }
    setlinechartData({
      markers: {
        size: [4, 7],
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
      chart: {
        height: "400px",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: linedata1,
      },
    });
    setlinechartYData([
      {
        name: "Alerts",
        data: linedata,
      },
    ]);
  };
  const FormDetails = () => {
    setFarmlist([]);
    var data = JSON.parse(localStorage.getItem("Login"));
    var uid = data.data.UID;
    var url = Apis.WebfarmDetails;
    var req = { UID: uid, lang: dictionary.languageId };

    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode == 200) {
          console.log(JSON.stringify(response.data.data));
          // alert(JSON.stringify(response));
          setFarmlist(response.data.data);
          let firstfarmId = response.data.data[0].FID;
          setPFarmId(firstfarmId);

          BoothDetails(firstfarmId);

          //
        } else {
          // alert("else");
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })

      .catch((error) => {
        //   alert(error);
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const BoothDetails = (firstfarmId) => {
    setBoothlist([]);
    var url = Apis.WebBoothDetails;
    var req = { FID: firstfarmId, lang: dictionary.languageId };

    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode == 200) {
          console.log(JSON.stringify(response.data.data));
          setBoothlist(response.data.data);
          let firstboothid = response.data.data[0].BID;
          setPBoothId(firstboothid);
          lineChartDetails(firstfarmId, 1, firstboothid);

          //
        } else {
          // alert("else");
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })

      .catch((error) => {
        //   alert(error);
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const lineChartDetails = (farm, week, booth) => {
    var data = JSON.parse(localStorage.getItem("Login"));
    var uid = data.data.UID;
    setUserId(uid);
    var url = Apis.LineChart;

    var req = {
      uid: uid,
      formid: farm,
      week: week,
      boothid: booth,
      lang: dictionary.languageId,
    };

    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode === 200) {
          var data = response.data;
          linedataBinding(data);
        } else {
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  useEffect(() => {
    DashboardDetails();
    getNotes();
  }, []);
  useEffect(() => {
    lineChartDetails(PFarmId, PWeekId, PBoothId);
    FormDetails();
  }, [dictionary.languageId]);

  const NotesHandler = () => {
    var url = Apis.UpdateNotes;
    var req = {
      uid: UserId,
      notes: UserNotes,
    };

    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode == 200) {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getNotes();
        } else {
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })

      .catch((error) => {
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const showTable = () => {
    return BoothDailyDetails.map((item, index) => {
      return (
        <tr>
          <td> {index + 1}</td>
          <td>{item._id.booth}</td>
          <td>{item._id.boothid}</td>
          <td>{item._id.farm}</td>
          <td>{item.boothCount}</td>
        </tr>
      );
    });
  };

  const showTable1 = () => {
    return FormDailyDetails.map((item, index) => {
      return (
        <tr>
          <td>{index + 1}</td>
          <td>{item._id.farm}</td>
          <td>{item._id.farmid}</td>
          <td>{item.farmCount}</td>
        </tr>
      );
    });
  };

  const SelectFormHandler = (event) => {
    var select = Number(event.target.value);
    setPWeekId(select);
    lineChartDetails(PFarmId, select, PBoothId);
  };

  const SelectFormHandler1 = (event) => {
    var select = event.target.value;
    setPFarmId(select);
    BoothDetails(select);
  };
  const SelectBoothHandler = (event) => {
    var select = event.target.value;
    setPBoothId(select);
    lineChartDetails(PFarmId, PWeekId, select);
  };

  return (
    <div className="pagetp">
      <div className="row">
        <div className="col-12">
          {loader ? (
            <div className="overlay">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spinner">
                    <img
                      src={"/images/loader1.gif"}
                      alt=""
                      className="loadersiz"
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <ToastContainer
        // className="toast-position"
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {/* <div className="row">
        <div className="col-md-12 minutop mb-4">
          <div className="datepicinput">
            <div className="datepicc">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  placeholder="From date"
                  value={selectedDatefrom}
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  onChange={datefrom}
                  format="dd/MM/yyyy"
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="datepicc">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  placeholder="To date"
                  value={selectedDateto}
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  onChange={dateto}
                  format="dd/MM/yyyy"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
              <div className="row">
                <div className="dashhed">{dictionary.totalalerts}</div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="yearbox yeartoday">
                        <div className="year_text">{dictionary.Today}</div>
                        <div className="year_num">{Today}</div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="yearbox">
                        <div className="year_text">{dictionary.Week}</div>
                        <div className="year_num">{Week}</div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="yearbox">
                        <div className="year_text">{dictionary.Month}</div>
                        <div className="year_num">{Month}</div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="yearbox">
                        <div className="year_text">{dictionary.Last_Month}</div>
                        <div className="year_num">{Pmonth}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
              <div className="row">
                <div className="col-12" style={{ textAlign: "center" }}>
                  <div className="dashhed">{dictionary.totalboothalerts} </div>
                </div>
                <div className="col-12 pichartss">
                  {/* <Doughnut
                    className="pich_center"
                    data={chartData}
                    options={options_donut}
                  /> */}
                  <Chart
                    options={options_apex}
                    series={PieChartDetails}
                    type="donut"
                    height="280"
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="textareaback">
                <div className="linechartbox_select">
                  <Button
                    className="btn btn_logs"
                    variant="contained"
                    onClick={NotesHandler}
                  >
                    {dictionary.updatenotes}
                  </Button>
                </div>
                <textarea
                  placeholder={dictionary.notepad}
                  className="notetextarea"
                  value={UserNotes}
                  onChange={(e) => setUserNotes(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            <div className="dashhed">{dictionary.dailyalerts}</div>
            <div className="col-12">
              <div className="linechartbox">
                <div className="linechartbox_select">
                  <select onChange={SelectFormHandler}>
                    <option value="1">{dictionary.Week}</option>
                    <option value="2">{dictionary.Month}</option>
                    <option value="3">{dictionary.Year}</option>
                  </select>

                  {/* <select className="ml-2"></select> */}

                  <select
                    style={{ width: 150, marginLeft: 10 }}
                    onChange={SelectFormHandler1}
                  >
                    {Farmlist.map((item) => {
                      return <option value={item.FID}>{item.Farm_name}</option>;
                    })}
                  </select>

                  <select
                    style={{ width: 150, marginLeft: 10 }}
                    onChange={SelectBoothHandler}
                  >
                    {Boothlist.map((item) => {
                      return (
                        <option value={item.BID}>{item.Booth_name}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="chartbox_line">
                  {/* <Line
                    className="chartbox_con"
                    options={options}
                    data={linechartData}
                  /> */}

                  <Chart
                    className="linechartheight"
                    type="line"
                    series={linechartYData}
                    options={linechartData}
                    width="100%"
                    height="280"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mt-4">
          <div className="row">
            {BoothDailyDetails.length != 0 ? (
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
                <div className="dashhed">{dictionary.toptenbooths}</div>
                <div className="dashtable">
                  <tabel className="table">
                    <thead>
                      <tr>
                        <th>
                          <div>#</div>
                        </th>
                        <th>
                          <div>{dictionary.boothname}</div>
                        </th>
                        <th>
                          <div>{dictionary.boothid}</div>
                        </th>
                        <th>
                          <div>{dictionary.farmname}</div>
                        </th>
                        <th>
                          <div>{dictionary.Alerts}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{showTable()}</tbody>
                  </tabel>
                </div>
              </div>
            ) : null}
            {FormDailyDetails.length != 0 ? (
              <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                <div className="dashhed">{dictionary.toptenfarms}</div>
                <div className="dashtable">
                  <tabel className="table">
                    <thead>
                      <tr>
                        <th>
                          <div>#</div>
                        </th>
                        <th>
                          <div>{dictionary.farmname}</div>
                        </th>
                        <th>
                          <div>{dictionary.farmid}</div>
                        </th>
                        <th>
                          <div>{dictionary.Alerts}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{showTable1()}</tbody>
                  </tabel>
                </div>
              </div>
            ) : null}
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4"></div>
          </div>
        </div>

        {/* <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
          
          <div className="logbut">
            <Button
              className="btn btn_log"
              variant="contained"
              onClick={NotesHandler}
            >
              Update Notes
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default WDashboardPage;
