import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import Topheader from "components/Topheader/Topheader";
import Sidebar from "shared/navbar/Sidebar";
import { Container } from "reactstrap";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [auth, setAuth] = useState(localStorage.getItem("getToken"));

  //   useEffect(() => {
  //     var getToken = localStorage.getItem("getToken");
  //     setAuth(getToken);
  //   }, [auth]);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth == 1 ? (
          <>
            <div class="wrapper">
              <Topheader />
              <div class="main-panel">
                <div class="center-content">
                  <Container>
                    <Component {...props} />
                  </Container>
                </div>
              </div>
              <div class="sidebarleft">
                <Sidebar />
              </div>
            </div>
          </>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
