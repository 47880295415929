import React, { Component, useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import $ from "jquery";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { FaRegFileExcel } from "react-icons/fa";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Apis } from "api";
import { Text, LanguageContext } from "./../../context/LanguageContext";

const WFarmsPage = () => {
  const { dictionary } = useContext(LanguageContext);
  const [loader, setLoader] = useState(false);
  const [Farmlist, setFarmlist] = useState([]);
  useEffect(() => {
    $(document).ready(function () {
      setLoader(true);
      var data = JSON.parse(localStorage.getItem("Login"));
      var uid = data.data.UID;
      var url = Apis.FarmDetails;
      var req = { UID: uid };

      const requestHeader = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .post(url, req, requestHeader)
        .then(async (response) => {
          if (response.data.statuscode == 200) {
            console.log(JSON.stringify(response.data.data));
            if (response.data.data.length == 0) {
              setLoader(false);
            }
            // alert(JSON.stringify(response));
            setFarmlist(response.data.data);

            //
          } else {
            // alert("else");
            setLoader(false);
            toast.error(response.data.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })

        .catch((error) => {
          setLoader(false);
          //   alert(error);
          toast.error(error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    });
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".pageheads").html(dictionary.Farms);
    });
  });

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#myTable")) {
      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            initComplete: function (settings, json) {
              // alert( 'DataTables has finished its initialisation.' );
              $(".blockdis").show();
              $(".nonedis").hide();
              setLoader(false);
            },
            processing: true,
            bDestroy: true,
            pagingType: "full_numbers",
            autoWidth: false,
            language: {
              loadingRecords: "&nbsp;",
              processing: "Loading...",
              search: "",
              searchPlaceholder: dictionary.search,
              paginate: {
                last: '<img src="images/arrow-right_fast.png">',
                first: '<img src="images/arrow-left_fast.png">',
                previous: '<img src="images/arrow-left.png">',
                next: '<img src="images/arrow-right.png">',
              },
              info:
                dictionary.Showing +
                " " +
                "_START_" +
                " " +
                dictionary.to +
                " " +
                "_END_" +
                " " +
                dictionary.of +
                " " +
                "_TOTAL_" +
                " " +
                dictionary.entries,
              lengthMenu:
                dictionary.show + " " + "_MENU_" + " " + dictionary.entries,
            },
            pageLength: 10,
            processing: true,
            dom: '<"dt-top-container"<"dt-len" l><"dt-center-in-div"f><"buttonsclass" B>r>t<"dt-filter-spacer"><ip>',

            select: {
              style: "single",
            },
            buttons: [
              {
                extend: "excel",
                className: "excelButton",
                text: '<img style="width:32px;height:32px;" src="images/grid_table/excel.png">',
                titleAttr: dictionary.export_excel,
                filename: dictionary.Farms,
              },
            ],
            // buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
            fnRowCallback: function (
              nRow,
              aData,
              iDisplayIndex,
              iDisplayIndexFull
            ) {
              var index = iDisplayIndexFull + 1;
              $("td:first", nRow).html(index);
              return nRow;
            },
            lengthMenu: [
              [10, 20, 50, 100],
              [10, 20, 50, 100],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });
        }, 1000);
      });
    }
  });

  const showTable = () => {
    try {
      return Farmlist.map((item, index) => {
        return (
          <tr>
            <td style={{ textAlign: "center" }} className="">
              {index + 1}
            </td>
            <td className="">{item.FID}</td>
            <td className="">{item.Farm_name}</td>
            <td className="">{item.Fkey}</td>
            <td className="">{item.Booth_Count}</td>
            <td className="">{item.Location}</td>
            <td className="">
              {moment(item.createdIso).format("MMM Do YYYY, hh:mm a")}
            </td>
          </tr>
        );
      });
    } catch (e) {
      // alert(e.message);
    }
  };

  return (
    <div className="pagetp">
      <div className="row">
        <div className="col-12">
          {loader ? (
            <div className="overlay">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spinner">
                    <img
                      src={"/images/loader1.gif"}
                      alt=""
                      className="loadersiz"
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {Farmlist.length != 0 ? (
        <div>
          {/* <div class="nonedis Tableprocessing">{dictionary.loading}</div> */}
          <div
            style={{ display: "none" }}
            class="blockdis table-responsive p-0 pb-2"
          >
            <table id="table" className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style={{ width: 40 }} className="">
                    {dictionary.sno}
                  </th>
                  <th style={{ width: 120 }} className="">
                    {dictionary.farmid}
                  </th>
                  <th style={{ minWidth: 200 }} className="">
                    {dictionary.farmname}
                  </th>
                  <th style={{ width: 90 }} className="">
                    {dictionary.farmkey}
                  </th>
                  <th style={{ width: 90 }} className="">
                    {dictionary.Booths}
                  </th>
                  <th style={{ minWidth: 200 }} className="">
                    {dictionary.location}
                  </th>
                  <th style={{ minWidth: 200 }} className="">
                    {dictionary.createddate}
                  </th>
                </tr>
              </thead>

              <tbody>{showTable()}</tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WFarmsPage;
