import React, { useState, useEffect, useContext } from "react";
import { FaRegBell } from "react-icons/fa";
import { languageOptions } from "./../../language";
import { LanguageContext, Text } from "./../../context/LanguageContext";

const Topheader = (props) => {
  const [Name, setName] = useState("admin");
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const handleLanguageChange = (e) => userLanguageChange(e.target.value);
  useEffect(() => {
    var name = JSON.parse(localStorage.getItem("LoginName"));
    setName(name);
  }, []);

  return (
    <div className="headerbar">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="pageheads"></div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="usermanage">
            {/* <div className="user_man">
              <FaRegBell className="headnotification" />
            </div> */}
            <select onChange={handleLanguageChange} value={userLanguage}>
              {Object.entries(languageOptions).map(([id, name]) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
            <div className="user_man">
              <div className="">
                <div class="dropdown">
                  <button class="dropbtn">
                    <img className="headuserss" src={"images/users.png"} />{" "}
                    {Name}
                  </button>
                  {/* <div class="dropdown-content">
                            <a href="#">Option 1</a>
                            <a href="#">Option 2</a>
                            <a href="#">Option 3</a>
                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topheader;
