import React from "react";
import PropTypes from "prop-types";

const shapes = {
  RoundedBorder32: "rounded-radius3288",
  icbRoundedBorder17: "rounded-radius175",
  icbRoundedBorder5: "rounded-radius5",
};
const variants = {
  FillOrange400: "bg-orange_400 text-white_A700",
  icbOutlineBlack90019: "bg-white_A700 shadow-bs",
  icbFillWhiteA700: "bg-white_A700",
};
const sizes = {
  sm: "md:p-[11px] p-[22px] sm:px-[15px] sm:py-[8px]",
  smIcn: "sm:p-[1px] md:p-[2px] p-[5px]",
  mdIcn: "p-[10px] sm:p-[3px] md:p-[5px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "RoundedBorder32",
    "icbRoundedBorder17",
    "icbRoundedBorder5",
  ]),
  variant: PropTypes.oneOf([
    "FillOrange400",
    "icbOutlineBlack90019",
    "icbFillWhiteA700",
  ]),
  size: PropTypes.oneOf(["sm", "smIcn", "mdIcn"]),
};
Button.defaultProps = {
  className: "",
  shape: "icbRoundedBorder17",
  variant: "icbOutlineBlack90019",
  size: "smIcn",
};

export { Button };
