import React, { Component, useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";

import $ from "jquery";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { FaRegFileExcel } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Apis } from "api";
import { Text, LanguageContext } from "./../../context/LanguageContext";

const WAlertsPage = () => {
  const { dictionary } = useContext(LanguageContext);
  const [selectedDatefrom, setSelectedDatefrom] = useState(null);
  const [selectedDateto, setSelectedDateto] = useState(null);
  const [selectedApiDatefrom, setSelectedApiDatefrom] = useState(null);
  const [selectedApiDateto, setSelectedApiDateto] = useState(null);
  const [loader, setLoader] = useState(false);
  const [Alertlist, setAlertlist] = useState([]);

  useEffect(() => {
    $(document).ready(function () {
      $(".pageheads").html(dictionary.Alerts);
    });
  });

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#myTable")) {
      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            initComplete: function (settings, json) {
              // alert( 'DataTables has finished its initialisation.' );
              $(".blockdis").show();
              $(".nonedis").hide();
              setLoader(false);
            },
            processing: true,
            bDestroy: true,
            pagingType: "full_numbers",
            autoWidth: false,
            language: {
              loadingRecords: "&nbsp;",
              processing: "Loading...",
              search: "",
              searchPlaceholder: dictionary.search,
              paginate: {
                last: '<img src="images/arrow-right_fast.png">',
                first: '<img src="images/arrow-left_fast.png">',
                previous: '<img src="images/arrow-left.png">',
                next: '<img src="images/arrow-right.png">',
              },
              info:
                dictionary.Showing +
                " " +
                "_START_" +
                " " +
                dictionary.to +
                " " +
                "_END_" +
                " " +
                dictionary.of +
                " " +
                "_TOTAL_" +
                " " +
                dictionary.entries,
              lengthMenu:
                dictionary.show + " " + "_MENU_" + " " + dictionary.entries,
            },
            pageLength: 10,
            processing: true,
            dom: '<"dt-top-container"<"dt-len" l><"dt-center-in-div"f><"buttonsclass" B>r>t<"dt-filter-spacer"><ip>',

            select: {
              style: "single",
            },
            buttons: [
              {
                extend: "excel",
                className: "excelButton",
                text: '<img style="width:32px;height:32px;" src="images/grid_table/excel.png">',
                titleAttr: dictionary.export_excel,
                filename: dictionary.Alerts,
              },
            ],
            // buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
            fnRowCallback: function (
              nRow,
              aData,
              iDisplayIndex,
              iDisplayIndexFull
            ) {
              var index = iDisplayIndexFull + 1;
              $("td:first", nRow).html(index);
              return nRow;
            },
            lengthMenu: [
              [10, 20, 50, 100],
              [10, 20, 50, 100],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });
        }, 1000);
      });
    }
  });

  const notificationApi = (From, To) => {
    setLoader(true);
    setAlertlist([]);
    var data = JSON.parse(localStorage.getItem("Login"));
    var uid = data.data.UID;
    var url = Apis.AlertDetails;
    var req = {
      UID: uid,
      startdate: From,
      endDate: To,
    };

    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, req, requestHeader)
      .then(async (response) => {
        if (response.data.statuscode == 200) {
          console.log(JSON.stringify(response.data.data));
          if (response.data.data.length == 0) {
            setLoader(false);
          }

          setAlertlist(response.data.data);
        } else {
          setLoader(false);
          toast.error(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })

      .catch((error) => {
        setLoader(false);
        // alert(error);
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 30));
    var apiTo = moment(today).format("YYYY-MM-DD");
    var apiFrom = moment(today).format("YYYY-MM-DD");
    setSelectedDatefrom(apiFrom);
    setSelectedDateto(apiTo);
    notificationApi(apiFrom, apiTo);
  }, []);

  const showTable = () => {
    try {
      return Alertlist.map((item, index) => {
        return (
          <tr>
            <td style={{ textAlign: "center" }} className="">
              {index + 1}
            </td>
            <td className="">{item.BID}</td>
            <td className="">{item.Booth_name}</td>
            <td className="">{item.FID}</td>
            <td className="">{item.Farm_name}</td>
            <td className="">{item.PigID}</td>
            <td className="">{item.Temparturevalues}</td>
            <td className="">{item.Time}</td>
            <td className="">{item.current_date}</td>
          </tr>
        );
      });
    } catch (e) {
      // alert(e.message);
    }
  };
  const datefrom = (newValue) => {
    var Date_set = newValue;
    let start = moment(Date_set).format("YYYY-MM-DD");
    setSelectedDatefrom(newValue);
    notificationApi(start, selectedDateto);
  };

  const dateto = (newValue) => {
    var Date_set = newValue;
    let end = moment(Date_set).format("YYYY-MM-DD");
    setSelectedDateto(end);
    notificationApi(selectedDatefrom, end);
  };

  return (
    <div className="pagetp">
      <div className="row">
        <div className="col-12">
          {loader ? (
            <div className="overlay">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spinner">
                    <img
                      src={"/images/loader1.gif"}
                      alt=""
                      className="loadersiz"
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-md-12 minutop mb-4">
              <div className="datepicinput">
                <div className="datepicc">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      placeholder="From date"
                      value={selectedDatefrom}
                      maxDate={selectedDateto}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      onChange={datefrom}
                      format="dd-MM-yyyy"
                      disableFuture
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="datepicc">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      placeholder="To date"
                      value={selectedDateto}
                      minDate={selectedDatefrom}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      onChange={dateto}
                      format="dd-MM-yyyy"
                      disableFuture
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          {Alertlist.length != 0 ? (
            <div>
              {/* <div class="nonedis Tableprocessing">{dictionary.loading}</div> */}
              <div
                style={{ display: "none" }}
                class="blockdis table-responsive p-0 pb-2"
              >
                <table
                  id="table"
                  className="table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th style={{ width: 40 }} className="">
                        {dictionary.sno}
                      </th>
                      <th style={{ width: 70 }} className="">
                        {dictionary.boothid}
                      </th>
                      <th style={{ width: 70 }} className="">
                        {dictionary.boothname}
                      </th>
                      <th style={{ width: 70 }} className="">
                        {dictionary.farmid}
                      </th>
                      <th style={{ width: 70 }} className="">
                        {dictionary.farmname}
                      </th>
                      <th style={{ width: 70 }} className="">
                        {dictionary.pigid}
                      </th>
                      <th style={{ width: 70 }} className="">
                        {dictionary.temperature}
                      </th>
                      <th style={{ width: 70 }} className="">
                        {dictionary.time}
                      </th>
                      <th style={{ width: 70 }} className="">
                        {dictionary.date}
                      </th>
                    </tr>
                  </thead>

                  <tbody>{showTable()}</tbody>
                </table>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{dictionary.noalertsfound}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WAlertsPage;
